import React from "react";
import { isNil, join } from "ramda";
import { FaAngleDown, FaCheck } from "react-icons/fa";
import useTranslations from "../../hooks/useTranslations";
import { rhythm, scale } from "../../utils/typography";
import { mq, truncate, localizedDate } from "../../utils/helper";
import ReactPlayer from "react-player/youtube";
import { colors } from "../../style/theme";

function Player({ link, youtube }) {
  const isNull = link === "https://cube.syrianarchive.org";
  const offline = (
    <video
      controls
      width="100%"
      css={{
        marginTop: rhythm(1),
        marginBottom: rhythm(1),
      }}
    >
      <source src={link} type="video/mp4"></source>
    </video>
  );
  const online = <ReactPlayer url={youtube} />;
  return isNull ? online : offline;
}

export default function ObservationDetails({ observation, locale }) {
  const tr = useTranslations();
  const isRTL = locale === "ar";
  let {
    id,
    collections,
    location,
    title_ar,
    title_en,
    incident_date: date,
    filename,
    incident_code: code,
    online_link,
    summary_ar,
    summary_en,
    weapons_used: weapon,
  } = observation;

  if (isNil(filename)) {
    filename = "empty";
  }
  let link;
  link = `https://cube.syrianarchive.org/${filename}`;

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gridColumnGap: rhythm(3),
        backgroundColor: colors.light,
        border: `3px solid ${colors.dark}`,
        padding: `${rhythm(3)} ${rhythm(5)}`,
        direction: isRTL ? "rtl" : "ltr",
        [mq[0]]: {
          display: "block",
          padding: rhythm(1),
        },
      }}
    >
      <div>
        <h2
          css={{
            marginTop: 0,
            [mq[0]]: {
              ...scale(0.6),
            },
          }}
        >
          {isRTL ? title_ar : title_en}
        </h2>
        <small css={{ display: "block", opacity: 0.6 }}>
          <FaCheck />
          {tr("Verified Observation")}: {code}
        </small>
        <small
          css={{
            backgroundColor: "#C20A0A",
            color: colors.light,
            fontWeight: "bold",
            padding: rhythm(0.2),
            paddingBottom: 0,
          }}
        >
          {tr("Warning: this video may contain graphic content")}
        </small>
        <Player youtube={online_link} link={link} />
        <a href={link}>
          {tr("Download file")} <FaAngleDown />
        </a>
        <dl
          css={{
            marginTop: rhythm(2),
          }}
        >
          <dt>{tr("Online Link")}</dt>
          <dd>
            <a href={online_link}>{tr("Link")}</a>
          </dd>
        </dl>
      </div>

      <div>
        <dl>
          <dt>{tr("Incident")}:</dt>
          <dd>{code}</dd>
          <dt>{tr("Summary")}</dt>
          <dd
            css={{
              lineBreak: "strict",
              maxHeight: "300px",
              maxWidth: "300px",
              overflowY: "auto",
            }}
          >
            {isRTL ? summary_ar : summary_en}
          </dd>
          <dt>{tr("Date of occurency")}</dt>
          <dd>{localizedDate(date, "y,M,d")}</dd>
          <dt>{tr("Location")} </dt>
          <dd>{location}</dd>
          <dt>{tr("Collections")}:</dt>
          <dd>{collections}</dd>
        </dl>
      </div>
    </div>
  );
}
