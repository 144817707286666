import React from "react";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import Layout from "../../components/layout";
import ObservationModal from "../../components/modal";

let windowWidth;

export default function ObservationLayout({
  location,
  isModal = false,
  children,
}) {
  const prevPath = location.state ? location.state.from : "/";
  if (!windowWidth && typeof window !== `undefined`) {
    windowWidth = window.innerWidth;
  }
  if (isModal && windowWidth > 750) {
    isModal = true;
  }
  if (isModal && ObservationModal) {
    return (
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => {
          return (
            <div>
              {modal ? (
                <ObservationModal
                  isOpen={true}
                  location={location}
                  prevPath={prevPath}
                >
                  {children}
                </ObservationModal>
              ) : (
                <div
                  css={{
                    background: `rgba(0,0,0,0.03)`,
                    minHeight: `100vh`,
                  }}
                ></div>
              )}
            </div>
          );
        }}
      </ModalRoutingContext.Consumer>
    );
  }

  return <Layout>{children}</Layout>;
}
