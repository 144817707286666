import React, { useContext } from "react";
import { graphql } from "gatsby";
import useLocale from "../hooks/useLocale";
import ObservationDetails from "../components/data/observation-details";
import ObservationLayout from "../components/data/observation-layout";

export default function ObservationsTemplate({ data, location }) {
  const { locale } = useLocale();
  let isModal = false;
  // We don't want to show the modal if a user navigates
  // directly to a unit so if this code is running on Gatsby's
  // initial render then we don't show the modal, otherwise we
  // do.
  if (
    typeof window !== "undefined" &&
    window.___MNEMONIC_INITIAL_RENDER_COMPLETE
  ) {
    isModal = true;
  }

  return (
    <ObservationLayout location={location} isModal={isModal}>
      <ObservationDetails observation={data.observationsJson} locale={locale} />
    </ObservationLayout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    observationsJson(id: { eq: $id }) {
      id
      collections
      location
      hash
      incident_date
      filename
      incident_code
      online_link
      title_ar
      title_en
      summary_ar
      summary_en
      weapons_used
      weapons_used2
    }
  }
`;
