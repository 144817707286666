import React from "react";
import Modal from "react-modal";
import { push } from "gatsby";
import { MdClose } from "react-icons/md";
import useLocale from "../hooks/useLocale";
import { colors } from "../style/theme";
import { rhythm } from "../utils/typography";
import { mq } from "../utils/helper";

Modal.setAppElement(`#___gatsby`);

export default function ObservationModal({
  location,
  isOpen,
  children,
  prevPath,
}) {
  const { locale, isAra } = useLocale();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() =>
        push(isAra ? `${locale}/${prevPath}` : `/${prevPath}`)
      }
      style={{
        overlay: {
          position: "fixed",
          top: 40,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.50)",
          zIndex: 10,
        },
        content: {
          marginTop: "3rem",
          position: "absolute",
          border: "none",
          background: "none",
          padding: 0,
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
        },
      }}
      contentLabel="Modal"
    >
      <div>
        <div
          css={{
            display: "flex",
            maxWidth: rhythm(47.25),
            margin: "auto",
            width: "100%",
          }}
        >
          {children}
        </div>
        <MdClose
          data-testid="modal-close"
          onClick={() => push(isAra ? `${locale}/${prevPath}` : `/${prevPath}`)}
          css={{
            cursor: "pointer",
            color: "rgba(255,255,255,0.8)",
            fontSize: "30px",
            position: "absolute",
            top: rhythm(1 / 4),
            right: rhythm(1 / 4),
            [mq[0]]: {
              color: colors.dark,
            },
          }}
        />
      </div>
    </Modal>
  );
}
